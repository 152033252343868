import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { awsVerifySession } from '../helpers/aws';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private _router: Router){}

  async canActivate() {
    try {
      const result = await awsVerifySession();
      if(!result.error){
        return true;
      }else{
        this._router.navigate(['/login']);
        return false;
      }
    } catch (e) {
      this._router.navigate(['/login']);
      return false;
    }
  }
  
}
