export const environment = { 
    production: 'false', 
    apiUrl: 'https://api.test.prosperas.com/v1',
    apiKey: '53788FFB-B0C2-48C7-929F-7130C2A52128',
    apiExpAzul: 'https://creditos-web.prosperas.com/broker.php',
    awsCognitoPool: 'us-east-1_3N5v83dqi',
    awsRegion: 'us-east-1',
    awsClientId: '278nmikc4m61vndmk7r6uuvvl',
    awsAccessKey: 'AKIAXJGKFZOM24UTQZ6O',
    awsSecret: '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',
    lang: 'es',
    logo: "https://mp-web-resources.s3.amazonaws.com/img/testenv.png"
};
