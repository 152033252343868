<div class="grid grid-cols-2 justify-items-center w-11/12">
  <div class="w-full col-span-2">
    <p class="text-2xl mb-3"><b>{{data.name}} </b></p>
  </div>
  <div>
    <p class="mt-2">{{items[0]}}: <b> {{data.description}} </b></p>
    <p class="mt-2">{{items[1]}}: <b> {{data.lowamount}} </b></p>
    <p class="mt-2">{{items[2]}}: <b> {{data.minpayperioddesc}} </b></p>
    <p class="mt-2">{{items[3]}}: <b> {{data.mintermmonthsdesc}} </b></p>
    <p class="mt-2">{{items[4]}}: <b> {{data.interestrate}} %</b></p>
  </div>
  <div>
    <p class="mt-2">&nbsp;</p>
    <p class="mt-2">{{items[5]}}: <b> {{data.highamount}} </b></p>
    <p class="mt-2">{{items[6]}}: <b> {{data.maxpayperioddesc}} </b></p>
    <p class="mt-2">{{items[7]}}: <b> {{data.maxtermmonthsdesc}} </b></p>
    <!--<p class="mt-2">Umbral de incumplimiento: <b> {{data.defaultthreshold || 0}} % </b></p>-->
    <!--<p class="mt-2">Probabilidad de incumplimiento: <b> {{data.defaultthreshold || 0}} % </b></p>-->
  </div>
  <br><br>
  <div class="w-full col-span-2">
    <p class="text-2xl mb-3"><b>&nbsp;</b></p>
  </div>
  <div *ngIf="data.leadconfig">
    <div class="w-full col-span-2">
      <p class="text-2xl mb-3"><b>{{items[8]}} </b></p>
    </div>
    <div>
      <p class="mt-2">{{items[9]}}: <b> {{data.leadconfig.name || data.leadconfig.type}} </b></p>
      <p class="mt-2" *ngFor="let field of data.leadconfig.fields">{{field}}: <b> {{data.leadconfig.data[field]}} </b>
      </p>
    </div>
  </div>
</div>
<br>
<div class="container text-right">
  <button class="btn btn-danger" (click)="edit_product(data)">{{items[10]}}</button>&nbsp;
  <button class="btn btn-danger" (click)="closeDialog()">{{items[11]}}</button>
</div>