import { Auth } from "aws-amplify";
import * as AWS from "aws-sdk";
import { environment } from "src/environments/environment";

export async function awsSignIn(username: string, password: string) {
    let signUser: any = null,
        errorCode: any = null,
        qrcodeValue: any = null;
    try {
        signUser = await Auth.signIn(username, password);
        if (signUser.challengeName === "MFA_SETUP") {
            const challenge = await Auth.setupTOTP(signUser);
            const issuer = "SolucionesBuroMoviles"; // TODO: Meter a variable de entorno
            qrcodeValue =
                "otpauth://totp/AWSCognito:" +
                signUser.username +
                "?secret=" +
                challenge +
                "&issuer=" +
                issuer;
        }
    } catch (e) {
        errorCode = e;
    }

    return { signUser, errorCode, qrcodeValue };
}

export async function awsResetPassword(username: string) {
    let result,
        error: any = null;
    try {
        result = await Auth.forgotPassword(username);
    } catch (e) {
        error = e;
    }
    return { result, error };
}

export async function awsVerifyResetPasswordCode(
    username: string,
    code: string,
    newPassword: string
) {
    let result,
        error: any = null;
    try {
        result = await Auth.forgotPasswordSubmit(username, code, newPassword);
    } catch (e) {
        error = e;
    }
    return { result, error };
}

export async function awsVerifyTotp(username: string, code: string) {
    let result,
        error: any = null;
    try {
        result = await Auth.verifyTotpToken(username, code);
        await Auth.setPreferredMFA(username, "TOTP"); // TODO: Add TOTP to env
    } catch (e) {
        error = e;
    }
    return { result, error };
}

export async function awsConfirmLogin(username: string, code: string) {
    let result: any = null,
        error: any = null;
    try {
        result = await Auth.confirmSignIn(
            username,
            code,
            "SOFTWARE_TOKEN_MFA" // TODO: Add to common-types
        );
    } catch (e) {
        error = e;
    }
    return { result, error };
}

export async function awsSignOut() {
    let result: any = null,
        error: any = null;
    try {
        result = await Auth.signOut({ global: true });
    } catch (e) {
        error = e;
    }
    return { result, error };
}

export async function awsVerifySession() {
    let result: any = null,
        error: any = null;
    await Auth.currentAuthenticatedUser()
        .then((data: any) => {
            result = data.attributes;
        })
        .catch((e: any) => {
            error = e;
        });
    return { result, error };
}

export async function awsCurrentUserGroup() {
    let result: string = "",
        error: any = null;
    await Auth.currentAuthenticatedUser()
        .then((data: any) => {
            result = data.signInUserSession.accessToken.payload["cognito:groups"][0];
        })
        .catch((e: any) => {
            error = e;
        });
    return { result, error };
}

export async function awsCurrentSesion() {
    let result: any = null,
        error: any = null;
    await Auth.currentSession()
        .then((data: any) => {
            result = data.getIdToken().getJwtToken();
        })
        .catch((e: any) => {
            error = e;
        });
    return { result, error };
}

export async function awsCurrentUserId() {
    let result: any = null,
        error: any = null;
    await Auth.currentAuthenticatedUser()
        .then((data: any) => {
            result = data.attributes["custom:bankIdentifier"];
        })
        .catch((e: any) => {
            error = e;
        });
    return { result, error };
}

export async function awsSignUpLender(
    email: string,
    password: string,
    id: number,
    name: string,
    phone: string,
    icon: string,
    country: string
) {
    let result: any = null,
        error: any = null;

    try {
        const { user } = await Auth.signUp({
            username: email,
            password,
            attributes: {
                email: email, // optional
                phone_number: phone, // optional - E.164 number convention
                // other custom attributes
                "custom:bankIdentifier": id,
                "custom:bankName": name,
                "custom:bankImage": icon,
                "custom:country": country,
            },
        });
        return user;
    } catch (error) {
        console.log(error);
        return;
    }
}

export async function addAWSLender(
    email: string,
    password: string,
    id: number,
    name: string,
    phone: string,
    icon: string,
    country: string
) {
    //let AWS = require("aws-sdk");

    console.log(`country = ${country}`);

    AWS.config.update({
        accessKeyId: environment.awsAccessKey,
        secretAccessKey: environment.awsSecret,
        region: environment.awsRegion,
    });
    const COGNITO_CLIENT = new AWS.CognitoIdentityServiceProvider({
        apiVersion: "2016-04-19",
        region: environment.awsRegion,
    });
    // AdminCreateUserRequest userRequest =
    //           new AdminCreateUserRequest().withUserPoolId(userPoolId).withUsername(email);

    var poolData = {
        UserPoolId: environment.awsCognitoPool,
        Username: email,
        DesiredDeliveryMediums: ["EMAIL"],
        TemporaryPassword: password,
        UserAttributes: [
            {
                Name: "email",
                Value: email,
            },
            {
                Name: "email_verified",
                Value: "true",
            },
            {
                Name: "phone_number",
                Value: phone,
            },
            {
                Name: "custom:bankIdentifier",
                Value: id.toString(),
            },
            {
                Name: "custom:bankName",
                Value: name,
            },
            {
                Name: "custom:bankImage",
                Value: icon,
            },
            {
                Name: "custom:country",
                Value: country,
            },
        ],
    };

    /*
    function (error: any, data: any) {
        console.log(`error = ${error}`);
        console.log(`data = ${data}`);
        console.log(`data.User = ${data.User}`);
        userid = data.User.Username;
        //return data;
        // callback(null, {
        //     statusCode: 200,
        //     body: JSON.stringify(data),
        // });
    }
    , function (error: any, data: any) {
        console.log(error);
        console.log(data);
        //return data;
        // callback(null, {
        //     statusCode: 200,
        //     body: JSON.stringify(data),
        // });
    }
    */
    const result = await COGNITO_CLIENT.adminCreateUser(poolData).promise();
    console.log(`result = ${JSON.stringify(result, null, 2)}`);
    var userid = result?.User?.Username ?? "";
    console.log(`userid = ${userid}`);

    var passwordData = {
        UserPoolId: environment.awsCognitoPool,
        Username: userid.toString(),
        Password: password,
        Permanent: true,
    };

    const passResult = await COGNITO_CLIENT.adminSetUserPassword(passwordData).promise();

    var groupParams = {
        GroupName: "Lenders" /* required */,
        UserPoolId: environment.awsCognitoPool /* required */,
        Username: userid.toString() /* required */,
    };
    const groupResult = await COGNITO_CLIENT.adminAddUserToGroup(groupParams).promise();

    return;
}



export async function addAWSUser(email: string, password: string, name: string) {
    AWS.config.update({
        accessKeyId: environment.awsAccessKey,
        secretAccessKey: environment.awsSecret,
        region: environment.awsRegion,
    });

    const COGNITO_CLIENT = new AWS.CognitoIdentityServiceProvider({
        apiVersion: "2016-04-19",
        region: environment.awsRegion,
    });

    const poolData = {
        UserPoolId: environment.awsCognitoPool,
        Username: email,
        DesiredDeliveryMediums: ["EMAIL"],
        TemporaryPassword: password,
        UserAttributes: [
            { Name: "email", Value: email },
            { Name: "email_verified", Value: "true" },
            { Name: "phone_number", Value: "" },
            { Name: "custom:bankIdentifier", Value: "" },
            { Name: "custom:bankName", Value: "" },
            { Name: "custom:bankImage", Value: "" },
            { Name: "custom:country", Value: "" },
        ],
    };

    try {
        const result = await COGNITO_CLIENT.adminCreateUser(poolData).promise();
        console.log(`result = ${JSON.stringify(result, null, 2)}`);
        const userid = result?.User?.Username ?? "";
        console.log(`userid = ${userid}`);

        const passwordData = {
            UserPoolId: environment.awsCognitoPool,
            Username: userid.toString(),
            Password: password,
            Permanent: true,
        };

        await COGNITO_CLIENT.adminSetUserPassword(passwordData).promise();

        const groupParams = {
            GroupName: "Admins",
            UserPoolId: environment.awsCognitoPool,
            Username: userid.toString(),
        };

        await COGNITO_CLIENT.adminAddUserToGroup(groupParams).promise();
    } catch (error) {
        // Handle or rethrow the error as needed
        throw new Error(`Error in addAWSUser function`);
    }
}