import { NgModule } from "@angular/core";
import { environment } from "src/environments/environment";
import { BrowserModule } from "@angular/platform-browser";
import { HttpClientModule } from "@angular/common/http";
import { HashLocationStrategy, LocationStrategy } from "@angular/common";
import { MatTableModule } from "@angular/material/table";
import { MatNativeDateModule, MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material/core';

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { MatSnackBarModule } from "@angular/material/snack-bar";

// MAR-44 Conversion page filter creation
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MY_DATE_FORMATS } from './helpers/date-formats';

import { CommonModule } from "@angular/common";

// AWS Amplify
import Amplify from "aws-amplify";
import { ConfirmDialogComponent } from "./components/confirm-dialog/confirm-dialog.component";
import { LeadsDetailsComponent } from "./components/leads-details/leads-details.component";
import { ProductsDetailsComponent } from "./components/products-details/products-details.component";
//import { UserAddComponent } from './modules/private/user/user-add/user-add.component';
//import { UserAllComponent } from './modules/private/user/user-all/user-all.component';

// Amplify.configure({
//   Auth: {
//     region: 'us-west-2',
//     identityPoolRegion: 'us-west-2',
//     userPoolId: 'us-west-2_Xh4FcifW1',
//     userPoolWebClientId: '6qkbhcdp83n3gaqpm24rh2gkdj',
//   },
// });

// Auth: {
//     region: "us-east-1",
//     identityPoolRegion: "us-east-1",
//     userPoolId: "us-east-1_3N5v83dqi",
//     userPoolWebClientId: "2krkpkfa6bbiolr22n4sk4q0oc",
//     secret: "1vvinua0610qe7u026rtu2gcu9fi21e1q2gaq3uagcl73u77pjq0",
// },

Amplify.configure({
    Auth: {
        region: environment.awsRegion,
        identityPoolRegion: environment.awsRegion,
        userPoolId: environment.awsCognitoPool,
        userPoolWebClientId: environment.awsClientId,
    },
});
// End AWS Amplify

@NgModule({
    declarations: [
        AppComponent,
        ConfirmDialogComponent,
        LeadsDetailsComponent,
        ProductsDetailsComponent,
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        HttpClientModule,
        MatTableModule,
        MatSnackBarModule,
        MatNativeDateModule,
        MatPaginatorModule,
        MatSortModule,
        MatProgressSpinnerModule,
        MatIconModule,
        MatDialogModule,
        MatFormFieldModule,
        MatInputModule,
        MatDatepickerModule
    ],
    providers: [{ provide: LocationStrategy, useClass: HashLocationStrategy },
                { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
                { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
