import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { LanguageService } from "src/app/services/language/language.service";

@Component({
  selector: 'app-products-details',
  templateUrl: './products-details.component.html',
  styleUrls: ['./products-details.component.scss'],
})
export class ProductsDetailsComponent implements OnInit {

  public spanish_items = [
    "Descripción",
    "Cantidad mínima",
    "Periodo mínimo de pago",
    "Plazo mínimo de pago",
    "Tasa de interes",
    "Cantidad máxima",
    "Periodo máximo de pago",
    "Plazo máximo de pago",
    "Datos de entrega de lead",
    "Tipo",
    "Editar",
    "Cerrar"
  ];

  public english_items = [
    "Description",
    "Minimum amount",
    "Minimum paying period",
    "Minimum paying term",
    "Interest rate",
    "Maximum amount",
    "Maximum paying period",
    "Maximum paying term",
    "Lead delivery data",
    "Type",
    "Edit",
    "Close"
  ];

  public items = ["", ""];

  constructor(
    private _router: Router,
    private idioma: LanguageService,
    public dialogRef: MatDialogRef<ProductsDetailsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
  }

  ngOnInit(): void {
    if(this.idioma.idioma == "es") {
      this.items = this.spanish_items;
    }
    else {
        this.items = this.english_items;
    }
  }

  edit_product(data:any) {
    console.log(data);
    this.dialogRef.close();
    this._router.navigate(['/products-edit']);
  }

  closeDialog(){
    this.dialogRef.close();
  }
}
