import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

import Swal from 'sweetalert2';


@Injectable({
  providedIn: 'root'
})
export class GetDocumentsService {
  private _apiExpAzul = environment.apiExpAzul;

  public _documents = [];
  private FOLDER_ID: any = "";

  
  get DOCUMENTS(): any {
    return [...this._documents]
  }

  constructor(
  ) {}

  //Llamada a DEV "get_documents"
  async getDocument(folder_id: any): Promise<any> {
    this.FOLDER_ID = folder_id.folderid;
    console.log('FOLDER_ID: ', this.FOLDER_ID);
    

    if (folder_id.status != null) {
      /* Petición local */
      const axios = require('axios');
      const FormData = require('form-data');
      let data = new FormData();
      data.append('clase', 'services');
      data.append('metodo', 'get_documents');
      data.append('customer_id', '321');
      data.append('token_key', 'qxQ6Od9LHAaWSjyBrICR');
      data.append('folder_id', this.FOLDER_ID);
  
  
      let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${this._apiExpAzul}`,
        headers: { 
          //...data.getHeaders()
        },
        data : data
      };
  
      await axios.request(config)
      .then((response: any) => {
        if(response.data[0] == "OK") {
          if(JSON.parse(response.data[1]["data"]).length > 0) {
            this._documents = JSON.parse(response.data[1]["data"]);
          } else {
            console.log("No regreso documentos la API");
          }
        } else {
          console.log("Error al consultar la API");
        }
      })
      .catch((error: any) => {
        console.log(error);
      });
    } else {
      return this._documents = [];
    };
  };

  async no_documents(): Promise<any> {
    return this._documents = [];
  }

  //Llamada a DEV "update_status_accept"
  async update_status_accept(document_id: any): Promise<any> {
    /* Petición local */
    const axios = require('axios');
    const FormData = require('form-data');
    let data = new FormData();
    data.append('clase', 'services');
    data.append('metodo', 'update_status_accept');
    data.append('customer_id', '321');
    data.append('token_key', 'qxQ6Od9LHAaWSjyBrICR');
    data.append('folder_id', this.FOLDER_ID);
    data.append('document_id', document_id);



    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `${this._apiExpAzul}`,
      headers: { 
        //...data.getHeaders()
      },
      data : data
    };

    await axios.request(config)
    .then((response: any) => {
      if(response.data[0] == "OK") {
        Swal.fire({
          title: 'Documento aceptado.', 
          icon: 'success'
        });
        // console.log('update_status_accept: ', response.data);
      } else {
        console.log("Error al consultar la API");
      }
    })
    .catch((error: any) => {
      console.log(error);
    });
  };

  //Llamada a DEV "update_status_reject"
  async update_status_reject(document_id: any, comments: any): Promise<any> {
    /* Petición local */
    const axios = require('axios');
    const FormData = require('form-data');
    let data = new FormData();
    data.append('clase', 'services');
    data.append('metodo', 'update_status_reject');
    data.append('customer_id', '321');
    data.append('token_key', 'qxQ6Od9LHAaWSjyBrICR');
    data.append('folder_id', this.FOLDER_ID);
    data.append('document_id', document_id);
    data.append('comments', comments);


    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `${this._apiExpAzul}`,
      headers: { 
        //...data.getHeaders()
      },
      data : data
    };

    await axios.request(config)
    .then((response: any) => {
      console.log('update_status_reject: ', response.data);
      if(response.data[0] == "OK") {
        Swal.fire({
          title: 'Documento rechazado.', 
          icon: 'error'
        });
      } else {
        console.log("Error al consultar la API");
      }
    })
    .catch((error: any) => {
      console.log(error);
    });
  };

  //Llamada a DEV "Download_Document"
  async download_document(file_id: any): Promise<any> {
    /* Petición local */
    const axios = require('axios');
    const FormData = require('form-data');

    let data = new FormData();
    data.append('clase', 'services');
    data.append('metodo', 'download_document');
    data.append('customer_id', '321');
    data.append('token_key', 'qxQ6Od9LHAaWSjyBrICR');
    data.append('folder_id', this.FOLDER_ID);
    data.append('file_id', file_id);

    let config = {
      url: `${this._apiExpAzul}`,
      method: 'post',
      responseType: "blob",
      maxBodyLength: Infinity,
      data : data
    };

    await axios.request(config)
    .then((response: any) => {
      //return console.log(response.data);
      
      let url = window.URL.createObjectURL(new Blob([response.data]));
      const downloadLink = document.createElement("a");
      const fileName = "documento.jpeg";
      downloadLink.href = url;
      downloadLink.download = fileName;
      downloadLink.click();
      
      // Swal.fire({
      //   title: 'Documento descargado.', 
      //   icon: 'success'
      // });
    })
    .catch((error: any) => {
      console.log(error);
    });
  };

  //Llamada a DEV "Download_Document"
  async download_contrato(file_id: any): Promise<any> {
    /* Petición local */
    const axios = require('axios');
    const FormData = require('form-data');

    let data = new FormData();
    data.append('clase', 'services');
    data.append('metodo', 'download_document');
    data.append('customer_id', '321');
    data.append('token_key', 'qxQ6Od9LHAaWSjyBrICR');
    data.append('folder_id', this.FOLDER_ID);
    data.append('file_id', file_id);

    let config = {
      url: `${this._apiExpAzul}`,
      method: 'post',
      responseType: "blob",
      maxBodyLength: Infinity,
      data : data
    };

    await axios.request(config)
    .then((response: any) => {
      let url = window.URL.createObjectURL(new Blob([response.data]));
      const downloadLink = document.createElement("a");
      const fileName = "contrato.pdf";
      downloadLink.href = url;
      downloadLink.download = fileName;
      downloadLink.click();
      
      // Swal.fire({
      //   title: 'Documento descargado.', 
      //   icon: 'success'
      // });
    })
    .catch((error: any) => {
      console.log(error);
    });
  };
}