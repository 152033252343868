<div class="grid grid-cols-1 justify-items-center w-full" style="height: 85vh;">
  <h1 class="text-2xl mb-3 w-full ml-10"><b>{{data.lead.name}} </b></h1>
  <div class="
  w-full h-72
  grid grid-cols-2 gap-20
  ">
    <div class="
    w-full h-full
    p-5
    rounded-xl
    bg-[#f1f1f1]
    mt-5
    "
    style="-webkit-box-shadow: 11px 12px 13px -5px rgba(0,0,0,0.3);
    -moz-box-shadow: 11px 12px 13px -5px rgba(0,0,0,0.3);
    box-shadow: 11px 12px 13px -5px rgba(0,0,0,0.3);"
    >
      <p class="mt-2" *ngIf="!data.sponsor"><b>Email: </b>{{data.lead.email}}</p>
      <p class="mt-2" *ngIf="!data.sponsor"><b>{{items[0]}}: </b>{{data.lead.phone}}</p>
      <p class="mt-2"><b>{{items[1]}}: </b>{{data.lead.productname}}</p>
      <p class="mt-2"><b>{{items[2]}}: </b>{{data.lead.statusg}}</p>
      <p class="mt-2"><b>{{items[3]}}: </b>{{data.lead.selected}}</p>
      <p class="mt-2"><b>{{items[15]}}: </b>{{data.lead.id}}</p>
      <p class="mt-2"><b>{{items[16]}}: </b>{{data.lead.orgname}}</p>
      <p class="mt-2"><b>{{items[17]}}: </b>{{data.lead.probability}}</p>
      <p class="mt-2"><b>{{items[18]}}: </b>{{data.lead.score}}</p>
    </div>

    <div class="w-full h-full">
      <div class="w-full h-full
      p-5
      rounded-xl
      bg-[#f1f1f1]
      mt-5
      "
      style="-webkit-box-shadow: 11px 12px 13px -5px rgba(0,0,0,0.3);
      -moz-box-shadow: 11px 12px 13px -5px rgba(0,0,0,0.3);
      box-shadow: 11px 12px 13px -5px rgba(0,0,0,0.3);"
      >
        <p class="text-2xl mb-3">{{items[4]}}: </p>
        <p class="mt-2"><b>{{items[5]}}: </b>{{productcategory}}</p>
        <p class="mt-2"><b>{{items[6]}}: </b>{{lowamount}} a {{highamount}}</p>
        <p class="mt-2"><b>{{items[7]}}: </b>{{income}}</p>
        <p class="mt-2"><b>{{items[8]}}: </b>{{productpurpose}}</p>
      </div>
    </div>

  </div>
  <!-- <div class="w-full h-1">
    <hr>
  </div> -->
  <br />
  <br />
  <br />
  <br />
  <div class="w-full h-1">
    <hr>
  </div>
  <br />

  <div class="w-full h-28">
    <table *ngIf="!data.sponsor" mat-table [dataSource]="DOCUMENTS" matSort
      class="
      w-full h-full
      ">
      <ng-container matColumnDef="collecteddate" class="w-full" style="width: 90%;height: 100%;">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="w-full"><b class="text-lg" >{{items[9]}}</b></th>
        <td mat-cell *matCellDef="let row" class="
        w-full
        grid grid-cols-2
        hover:bg-[#1d1d1d1d]
        ">
          <!-- Con 'Files' -->
          <div class="flex justify-start items-center gap-1.5" *ngIf="row.files.length" >
            <svg height="15" width="15" viewBox="0 96 960 960" style="display: inline-block;">
              <path d="M220 876h150V626h220v250h150V486L480 291 220 486v390Zm-60 60V456l320-240 320 240v480H530V686H430v250H160Zm320-353Z" />
            </svg>
            {{row.name}}
            <div>
              <label> | {{items[10]}}: {{row.status}}</label>
            </div>
          </div>
          <div class="{{row.name}} flex justify-end items-center" *ngIf="row.files.length" >
            <!-- Caso ine y comp. domicilio -->
            <div *ngIf="row.name != 'Términos y condiciones'" >
              <div *ngIf="!isArray(row.form_values)"
                class="cursor-pointer 
                w-5 h-5
                " 
                style="width: auto;" (click)="show_div(row.document_id)" >
                <span style="text-decoration: underline;color: grey;">info</span>
              </div>
            </div>
            <!-- Caso Términos y condiciones -->
            <div *ngIf="row.name == 'Términos y condiciones'" >
              <div *ngIf="!isArray(row.form_values)"
                class="cursor-pointer 
                w-5 h-5
                " 
                style="width: auto;"  (click)="dowloadTerms(row)">
                <span style="text-decoration: underline;color: grey;">Descargar</span>
              </div>
            </div>

            <div 
              class="cursor-pointer 
              mx-2.5 my-2.5
              w-5 h-5
              " (click)="accept_document(row.document_id)" >
              <img src="../../../assets/aceptar_icon.png" class="w-full h-full" />
            </div>
            <div 
              class="cursor-pointer 
              mr-5 my-2.5
              w-5 h-5
              " (click)="reject_document(row.document_id)" >
              <img src="../../../assets/cancelar_icon.png" class="w-full h-full" />
            </div>
            <div 
              class="cursor-pointer 
              my-2.5
              w-5 h-5
              " (click)="downloadDocument(row.files[0].file_id)" >
              <img src="../../../assets/descarga_icon.png" class="w-full h-full" />
            </div>
          </div>
          <!-- Sin 'Files' -->
          <div class="flex justify-start items-center gap-1.5" *ngIf="!row.files.length" >
            <svg height="15" width="15" viewBox="0 96 960 960" style="display: inline-block;">
              <path d="M220 876h150V626h220v250h150V486L480 291 220 486v390Zm-60 60V456l320-240 320 240v480H530V686H430v250H160Zm320-353Z" />
            </svg>
            {{row.name}}
          </div>

          <!--<table *ngIf="!data.sponsor" mat-table [dataSource]="objectKeys(row.form_values)" matSort
            class="
            w-full h-full
            ">
            <ng-container matColumnDef="collecteddate" class="w-full" style="width: 90%;height: 100%;">
              <th mat-header-cell *matHeaderCellDef mat-sort-header class="w-full"><b class="text-lg" >{{items[9]}}</b></th>
              <td mat-cell *matCellDef="let key" class="
              w-full
              grid grid-cols-2
              hover:bg-[#1d1d1d1d]
              ">
                  {{row.form_values[key].name}} {{row.form_values[key].value}}
              </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns2" style="height: 25px !important;" ></tr>
            <tr mat-row *matRowDef="let row2; columns: displayedColumns2;" style="height: 25px !important;" ></tr>

            <tr class="mat-row" *matNoDataRow>
              <td class="mat-cell" colspan="4">{{items[11]}} </td>
            </tr>
          </table>-->

        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns2" style="height: 25px !important;" ></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns2;" style="height: 25px !important;" ></tr>

      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="4">{{items[11]}} </td>
      </tr>
    </table>
  </div>

  <br />
  <hr >
  <br />

  <div *ngIf="DOCUMENTS.length > 0" class="w-full " >
    <div class="
    flex items-center
    gap-3.5
    w-full 
    mt-2.5
    "
    style="
        height: 25rem;
        display: flex;
        justify-content: center;
        align-items: center;
    " >

      <div  class="flex"  style="width: 90%;height: 100%;">
        <div >
          <div *ngFor="let doc of DOCUMENTS">
              <table *ngIf="!isArray(doc.form_values) && this.actual_div==doc.document_id">
                <tr>
                  <th colspan="2"><b>{{ doc.name }}</b></th>
                </tr>
                <tr *ngFor='let key of objectKeys(doc.form_values)' class="hover:bg-[#1d1d1d1d]">
                  <td><b>{{ doc.form_values[key].name }}:</b></td>
                  <td>{{ doc.form_values[key].value }}</td>
                </tr>
              </table>
            <br>
          </div>
        </div>
      </div>
    </div>
    
    <!-- <p class="mt-2" style="display: none;">Scores: </p> -->
    <!--div class="w-full" style="
      display: grid;
      grid-template-columns: 63% 17% 18%;
      align-items: center;
      justify-content: flex-end;
      background: #fff;
      width: 100%;
      height: 40px;
    ">
      <span><b>Fecha de Score</b></span>
      <span><b>Score</b></span>
      <span><b>Probabilidad</b></span>
    </div>
    <table mat-table [dataSource]="scoreData" matSort >

      <ng-container matColumnDef="collecteddate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 20%;" class="hidden" > Fecha de Score </th>
        <td mat-cell *matCellDef="let row" style="width: 20%;"> {{row.collecteddate}} </td>
      </ng-container>
      <ng-container matColumnDef="score">
        <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 5%;" class="hidden" > Score </th>
        <td mat-cell *matCellDef="let row" style="width: 5%;"> {{row.score}} </td>
      </ng-container>

      <ng-container matColumnDef="probability">
        <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 5%;" class="hidden" > Probabilidad </th>
        <td mat-cell *matCellDef="let row" style="width: 5%;"> {{row.probability | percent:'1.1'}} </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

      <!-- Row shown when there is no matching data. -->
      <!--tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="4">No se encontró información </td>
      </tr>

    </table-->

    <!-- <br />
    <br /> -->

    
    <!-- <div class="
    flex items-center
    gap-3.5
    w-full h-64
    mt-2.5
    "
    style="display: none;" >

      <div style="width: 60%;" class="
      flex items-center
      ">
        <div class="
        buttons_docs
        flex flex-col
        gap-2.5
        " >
          <button class="
          flex justify-center
          p-4
          bg-gray-600 hover:bg-black
          text-white hover:text-white
          rounded-md" mat-raised-button >
            {{items[12]}}
          </button>

          <button class="
          flex justify-center
          p-4
          bg-gray-600 hover:bg-black
          text-white hover:text-white
          rounded-md" mat-raised-button >
            {{items[13]}}
          </button>

          <button class="
          flex justify-center
          p-4
          bg-gray-600 hover:bg-black
          text-white hover:text-white
          rounded-md" mat-raised-button >
            {{items[14]}}
          </button>
        </div>
        <div class="
        notes_docs
        ml-6
        " >
          <textarea placeholder="NOTAS" rows="4" cols="35"></textarea>
        </div>
        
      </div>
      

    </div> -->
  </div>
  <br><br><br><br><br><br><br><br><br><br>
</div>
