import { Component, Inject, OnInit } from "@angular/core";
import { MatTableModule } from "@angular/material/table";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { DatePipe, Time } from "@angular/common";
import { formatNumber } from "@angular/common";
import { LanguageService } from "src/app/services/language/language.service";

import { GetDocumentsService } from "src/app/services/get-documents/get-documents.service";
import Swal from 'sweetalert2';


@Component({
    selector: "app-leads-details",
    templateUrl: "./leads-details.component.html",
    styleUrls: ["./leads-details.component.scss"],
  providers: [DatePipe],
})
export class LeadsDetailsComponent implements OnInit {
  //public scores: any;
  public scoreData: any;
  public searchData: any;
  public loading: boolean = true;
  public productcategory: string = "";
  public lowamount: any = 0;
  public highamount: any = 0;
  public income: any = "";
  public productpurpose: any = "";
  
  public displayedColumns: string[] = ['collecteddate', 'score', 'probability'];
  public displayedColumns2: string[] = ['collecteddate'];

  public docsData: any[] = [];

  public objectKeys = Object.keys;
  public isArray = Array.isArray;
  public actual_div = 0;

  public spanish_items = [
    "Número telefónico",
    "Producto",
    "Status de solicitud",
    "Fecha de solicitud",
    "La búsqueda",
    "Categoría",
    "Cantidad",
    "Ingreso",
    "Objetivo",
    "Lista de documentos",
    "Estatus",
    "No se encontró información",
    "Aceptar",
    "Rechazar",
    "Descargar Expediente",
    "LeadID",
    "Org",
    "Probabilidad",
    "Score",
  ];

  public english_items = [
    "Phone Number",
    "Product",
    "Request Status",
    "Request Date",
    "Product Search",
    "Category",
    "Amount",
    "Income",
    "Reason",
    "Document List",
    "Status",
    "No info found",
    "Accept",
    "Reject",
    "Download File",
    "LeadID",
    "Org",
    "Probability",
    "Score",
  ];

  public items = ["",""];

  public DOC_FAKE = [
    {
      "name": "Validación de INE/IFE con reconocimiento facial",
      "document_id": 63661,
      "status": "preparing",
      "comments": "null",
      "valid_until": null,
      "files": [
        {
          "filename": "FILE_64d31305ddc11_216066.png",
          "file_id": 2300620
        },
        {
          "filename": "FILE_64d313088ad1a_216066.png",
          "file_id": 2300621
        },
        {
          "filename": "FILE_64d3130b1ef01_216066.png",
          "file_id": 2300622
        }
      ],
      "form_values": {
        "909072": {
          "name": "Validación de reconocimiento facial",
          "value": "VÁLIDA: Se encontró una similitud de 99.9639%"
        },
        "909073": {
          "name": "Validación de INE/IFE vs CURP",
          "value": "REVISAR: No fue posible validar, el CURP debe tener 18 caracteres"
        },
        "909074": {
          "name": "Validación de CURP",
          "value": "REVISAR: No fue posible validar, el CURP debe tener 18 caracteres"
        },
        "897297": {
          "name": "Resultado de proceso automático",
          "value": "REVISAR: Documento no válido o con baja calidad no permite extraer datos"
        },
        "897365": {
          "name": "Validación de CURP",
          "value": "VÁLIDA: AN - Alta Normal"
        },
        "897366": {
          "name": "CURP",
          "value": "GARD000120HMCNDGA1"
        },
        "897367": {
          "name": "Nombres",
          "value": "DIEGO"
        },
        "897368": {
          "name": "Primer Apellido",
          "value": "GANDARILLA"
        },
        "897369": {
          "name": "Segundo Apellido",
          "value": "RODRIGUEZ"
        },
        "897370": {
          "name": "Nombre completo",
          "value": "DIEGO GANDARILLA RODRIGUEZ"
        },
        "897371": {
          "name": "Sexo",
          "value": "HOMBRE"
        },
        "897372": {
          "name": "Fecha de nacimiento",
          "value": "20/01/2000"
        },
        "897373": {
          "name": "Estado de nacimiento",
          "value": "MEXICO"
        },
        "897374": {
          "name": "País",
          "value": "MEXICO"
        },
        "897375": {
          "name": "Estatus",
          "value": "OK"
        },
        "897376": {
          "name": "Código de Validación",
          "value": "vc1691440014.3063223"
        },
        "897377": {
          "name": "Estatus de CURP en RENAPO",
          "value": "VÁLIDA: RCC - Registro de Cambio Afectando a CURP"
        },
        "897378": {
          "name": "Documento Probatorio",
          "value": "1"
        },
        "897379": {
          "name": "Entidad de Registro",
          "value": "MÉXICO"
        },
        "897380": {
          "name": "Número de Acta",
          "value": "00865"
        },
        "897381": {
          "name": "Municipio de Registro",
          "value": "ECATEPEC"
        },
        "897382": {
          "name": "Año de Registro",
          "value": "2000"
        },
        "897383": {
          "name": "Clave de Entidad de Registro",
          "value": "15"
        },
        "897384": {
          "name": "Validación de vigencia",
          "value": "VÁLIDA. Esta vigente como medio de identificación y puede votar"
        },
        "897385": {
          "name": "Tipo de identificación",
          "value": "INE"
        },
        "897386": {
          "name": "CIC",
          "value": "201927232"
        },
        "897387": {
          "name": "Identificador ciudadano",
          "value": "048402874"
        },
        "897388": {
          "name": "Número de identificación",
          "value": "CIC: 201927232, Identificador ciudadano: 048402874"
        },
        "908681": {
          "name": "CURP",
          "value": "SIFC580109HDFMLR01"
        },
        "908682": {
          "name": "Nombres",
          "value": "CARLOS ANTONIO"
        },
        "908683": {
          "name": "Primer Apellido",
          "value": "SIMO"
        },
        "908684": {
          "name": "Segundo Apellido",
          "value": "FLOREZ"
        },
        "908685": {
          "name": "Nombre completo",
          "value": "CARLOS ANTONIO SIMO FLOREZ"
        },
        "908686": {
          "name": "Sexo",
          "value": "HOMBRE"
        },
        "908687": {
          "name": "Fecha de nacimiento",
          "value": "09/01/1958"
        },
        "908688": {
          "name": "Estado de nacimiento",
          "value": "DISTRITO FEDERAL"
        },
        "908689": {
          "name": "País",
          "value": "MEXICO"
        },
        "908690": {
          "name": "Estatus",
          "value": "OK"
        },
        "908691": {
          "name": "Código de Validación",
          "value": "vc1691542645.7266243"
        },
        "908692": {
          "name": "Estatus de CURP en RENAPO",
          "value": "VÁLIDA: AN - Alta Normal"
        },
        "908693": {
          "name": "Documento Probatorio",
          "value": "1"
        },
        "908694": {
          "name": "Entidad de Registro",
          "value": "DISTRITO FEDERAL"
        },
        "908695": {
          "name": "Número de Acta",
          "value": "00141"
        },
        "908696": {
          "name": "Municipio de Registro",
          "value": "MIGUEL HIDALGO"
        },
        "908697": {
          "name": "Año de Registro",
          "value": "1958"
        },
        "908698": {
          "name": "Clave de Entidad de Registro",
          "value": "09"
        },
        "908699": {
          "name": "Vigencia",
          "value": "31/Dec/2030"
        }
      }
    },
    {
      "name": "Comprobante de Domicilio",
      "document_id": 59488,
      "status": "preparing",
      "comments": null,
      "valid_until": null,
      "files": [],
      "form_values": []
    }
  ];


  constructor(
    private idioma: LanguageService,
    public dialogRef: MatDialogRef<LeadsDetailsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private datePipe: DatePipe,
    private _GetDocumentsService: GetDocumentsService
  ) {}


  get DOCUMENTS(): any {
    return this.docsData = this._GetDocumentsService.DOCUMENTS;
    //return this.docsData = this.DOC_FAKE;
  };




  ngOnInit(): void {
    //this._GetDocumentsService.getDocument(this.data.lead);
    if(this.idioma.idioma == "es") {
      this.items = this.spanish_items;
    }
    else {
        this.items = this.english_items;
    }
  }

  async ngAfterViewInit() {
    //console.log(this.data.lead);

    const searchhistory = await this.data.serv.getLeadSearchCriteria(
      this.data.lead.referencenumber
    );

    this.productcategory = searchhistory[0].productcategory;
    this.lowamount = searchhistory[0].lowamount;
    this.highamount = searchhistory[0].highamount;
    this.income = searchhistory[0].income;
    this.productpurpose = searchhistory[0].productpurpose;

    if(this.data.lead.folderid > 0){
      //this.docsData = this._GetDocumentsService.DOCUMENTS;
      this.docsData = await this._GetDocumentsService.getDocument(this.data.lead);
    } else {
      this.docsData = await this._GetDocumentsService.no_documents();
    };

    //const scores = await this.data.serv.getScores(this.data.lead.consumerid);
    //console.log(scores[0]);

    /*this.scoreData = new MatTableDataSource<any>(
      scores.map((score: any) => {
        //console.log(score);
        //formatNumber(score.probability, 'en-us', '1.0-0'),
        return {
          id: score.id,
          probability: score.probability,
          score: score.score,
          collecteddate: this.datePipe.transform(
            score.collecteddate,
            'yyyy-MM-dd'
          ),
        };
      })
    );*/
    this.loading = false;
  }




  async downloadDocument (file_id: any) {
    return this._GetDocumentsService.download_document(file_id);
  }

  async accept_document (document_id: any) {
    Swal.fire({
      title: '¿Aceptar el documento?',
      showCancelButton: true,
    }).then((result) => {
      if (result.value) {
        this._GetDocumentsService.update_status_accept(document_id);
        this.ngOnInit();
      }
      // if (result.value) {
      //   Swal.fire({
      //     text: '¿Seguro que quiere aceptar este documento?',
      //     showCancelButton: true,
      //   }).then((result) => {
      //     if (result.value) {
      //       return this._GetDocumentsService.update_status_accept(document_id);
      //     }
      //   });
      // }
    });
  }

  async reject_document (document_id: any) {
    Swal.fire({
      title: '<strong>¿Rechazar el documento?</strong>',
      icon: 'info',
      html:
        '<p>Escribe tus comentarios: </p> ' +
        '<textarea id="reject_document_comment" rows="4" cols="50">Sin comentarios.</textarea> ',
      showCancelButton: true,
    }).then((result) => {
      if (result.value) {
        let comentarios = document.getElementById('reject_document_comment')?.textContent;
        this._GetDocumentsService.update_status_reject(document_id, comentarios);
        this.ngOnInit();
      }
    });
  }

  show_div(div:any){
    if(div == this.actual_div){
      this.actual_div = 0;
    }else{
      this.actual_div = div;
    }
  };

  dowloadTerms(params:any){
    var files = params.files;

    if(files.length > 0) {
        var fileID = files[0]["file_id"];

        this._GetDocumentsService.download_contrato(fileID);
    };

    // for(let data in params) {
    //   let obj = params[data];

    //   console.log(obj.name);

    //   if(obj.value.includes('download') != true) {
    //     let href_name = obj.name;
    //     href_name = href_name.split('href="');1
    //     href_name = href_name[1].split('"');
    //     href_name = href_name[0];

    //     // console.log('https://eazul.prosperas.com'+href_name);
        
    //     // Crea un elemento para descargarlo
    //     const tempLink = document.createElement('a');
    //     tempLink.style.display = 'none';
    //     tempLink.href = href_name;//'https://eazul.prosperas.com'+href_name;
    //     tempLink.target = '_blank';
    //     tempLink.download = 'Terminos_condiciones.pdf'; // Nombre de archivo sugerido para descargar
    //     document.body.appendChild(tempLink);
    //     tempLink.click();
    //   };
      
      /* if(obj.value.includes('download') == false) {
        let href_name = obj.name;
        href_name = href_name.split('href="');
        href_name = href_name[1].split('"');
        href_name = href_name[0];

        // Crea un elemento para descargarlo
        const tempLink = document.createElement('a');
        tempLink.style.display = 'none';
        tempLink.href = href_name;
        tempLink.target = '_blank';
        tempLink.download = 'Terminos_condiciones.pdf'; // Nombre de archivo sugerido para descargar
        document.body.appendChild(tempLink);
        tempLink.click();
      }; */
    // };
  };

}
